import React, { Component, useMemo } from 'react';
import styled from '@emotion/styled';
import { Container } from 'layouts';
import theme from '@config/theme';
import AttachmentIcon from '@static/icons/attachment-icon.svg';
import { SectionHeading, SectionMeta } from '../components/Common';
//import Dropzone from 'react-dropzone';
import {Dropzone, useDropzone} from 'react-dropzone';
import { useState } from 'react';
import CMM_CONTACT_API from '../api/contact';
import { useEffect } from 'react';

const FormWrapper = styled.div`
  width: 60%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 0 20px 0;

  @media (max-width: ${theme.breakpoints.m}) {
    width: 80%;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Input = styled.input`
  height: 46px;
  border: 2px solid ${props => props.theme.colors.primary.base};
  border-radius: 23px;
  padding: 0 30px;
  line-height: 46px;
  font-size: 16px;
  outline: none;
  width: 100%;
  margin: 0 20px 30px 0;

  &:last-child {
    margin-right: 0 !important;
  }
`;

const Button = styled.button`
  height: 46px;
  border: none;
  border-radius: 23px;
  padding: 0 40px;
  line-height: 46px;
  font-size: 16px;
  outline: none;
  color: ${props => props.theme.colors.white.base};
  background: ${props => props.theme.colors.primary.base};
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.primary.light};
  }
`;

const AttachFileButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
`;

const AttachFileButtonText = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const AttachFileButtonicon = styled.img`
  height: 32px;
  width: 32px;
  margin-right: 30px;
  cursor: pointer;
  margin-bottom: 0px;
`;

const PickFile = styled.div`
  outline: none;
`;

const PickedFileList = styled.ul`
  list-style-type: none;  
  margin-top: 20px;
  margin-bottom: 20px;
`;

const PickedFile = styled.li`
  min-width: 500px;
  background: #F7F7F7;
  border: 2px solid #F1F1F1;
  border-radius: 23px;
  padding: 10px 20px;
  text-align: left;
  display: flex;
  alex-direction: row;
  justify-content: space-between;
`;

const FileName = styled.span``;

const RemoveIcon = styled.span`
  padding-left: 20px;
  font-weight: 600;
`;

const Paragraph = styled.p`
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 0px;
`;

const ErrorMessage = styled.h4`
  color: red;
`;

const SuccessMessage = styled.h4`
  color: green;
`;

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '10px 40px',
  borderWidth: 2,
  borderRadius: 200,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  fontWeight: '600',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const WorkTogether = () => {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  const defaultHireUsData = {
    firstName: '',
    lastName: '',
    email: '',
    description: '',
    errors:{
      firstName: '',
      email: '',
    }
  };

  const [hireUsData, setHireUsData] = useState(defaultHireUsData);
  const [loader, setLoader] = useState(false);
  const [formStatus, setFormStatus] = useState('new');
  const [selectedFiles, setSelectedFiles]=useState([]);
  
  const hireUs = (e) => {
    e.preventDefault();

    if(hireUsData.firstName.length === 0 || hireUsData.email.length === 0)
    {
      return setHireUsData({
        ...hireUsData,
        errors: {
          firstName: (!hireUsData.firstName.length) ?'Enter First Name' : '',
          email: (!hireUsData.email.length) ? 'Enter Email': ''
        }
      });
    }
    
    setLoader(true);

    // call hireus API
    CMM_CONTACT_API.hireUs({
        ...hireUsData,
        name: hireUsData.firstName+' '+hireUsData.lastName,
        files: selectedFiles
      })
      .then((response) => {
        const responseData = response.data;

        if (responseData.status === "success") {
          setFormStatus('success');
          setHireUsData(defaultHireUsData);
        } else {
          setFormStatus('error');  
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
        setFormStatus('error');
      }).finally(() => {
        setLoader(false);
      });
  }

  useEffect(() => {
    setSelectedFiles(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <Container>
      <SectionHeading>Let&apos;s Work Together</SectionHeading>
      <SectionMeta>We&#39;ll get back to you within one business day</SectionMeta>
      <FormWrapper>
        <Form>
          <FormGroup>
            <Input placeholder={'First Name'} value={hireUsData.firstName} onChange={(e) => setHireUsData({ ...hireUsData, firstName: e.target.value })}></Input>
            <Input placeholder={'Last Name'} value={hireUsData.lastName} onChange={(e) => setHireUsData({ ...hireUsData, lastName: e.target.value })}></Input>
          </FormGroup>
          <FormGroup>
            <Input placeholder={'Email'} value={hireUsData.email} onChange={(e) => setHireUsData({ ...hireUsData, email: e.target.value })}></Input>
          </FormGroup>
          <FormGroup>
            <Input placeholder={'Description'} value={hireUsData.description} onChange={(e) => setHireUsData({ ...hireUsData, description: e.target.value })}></Input>
          </FormGroup>
          <PickFile {...getRootProps({style: {...baseStyle}})}>
            <Input {...getInputProps()} />
            <AttachFileButtonicon src={AttachmentIcon} />
            <Paragraph>Attach files here</Paragraph>
          </PickFile>
          <PickedFileList>
            {selectedFiles.map((file, fileIndex) => (
              <PickedFile key={`at-fl-${fileIndex}`}>
                <FileName>{file.path}</FileName> <RemoveIcon onClick={() => setSelectedFiles(selectedFiles.filter(fl => fl.path != file.path))}>x</RemoveIcon>
              </PickedFile>
            ))}
          </PickedFileList>
          <ErrorMessage>{hireUsData.errors && hireUsData.errors.firstName}</ErrorMessage>
          <ErrorMessage>{hireUsData.errors && hireUsData.errors.email}</ErrorMessage>
          
          {formStatus === 'success' && <SuccessMessage>Thank you for submitting your response.</SuccessMessage> }
          {(loader || formStatus != 'success') && <Button onClick={hireUs}>Hire us</Button>}
          
        </Form>
      </FormWrapper>
    </Container>
  );
}

export default WorkTogether;
